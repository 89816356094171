import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  private monitoring = {
    pendingRequestsNumber: 0
  };
  public mobileSideBar : boolean = false;
  msgError: any = null;
  Response: any;
  constructor(
    private _http: HttpClient) {

  }
  post(url: string, data: any, options:any): Observable<any> {
    this.monitoring.pendingRequestsNumber++;
    const body = JSON.stringify(data);
    return this._http.post(url, data, options);
  }

  public extractData(res: Response) {
    const body:any = res.json();
    if (body['success']) {
      return body['result'] || {};
    }
    return console.log("There is some problem");
    
  }
  public handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return console.log(errMsg);
  }
  public handleLoginError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return console.log(errMsg);

  }
}
